<template>
    <div class="page-vip g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ pageName }}</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="page-wrap">
                <div class="page-side">
                    <div class="title" @click="showMenu = !showMenu">
                        <img src="@/assets/img/side-service.png" alt="">
                        客户服务
                    </div>
                    <div class="menu" :class="{show:showMenu}">
                        <div class="menu-item" v-for="(item,index) in menu" :key="index">
                            <div class="menu-item-tit"
                                 :class="{active:curPage === item.title}"
                                 @click="jumpTo(item)">
                                <h3>{{item.title}}</h3>
                                <p v-if="item.sub">{{item.sub}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-content">
                    <router-view/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            showMenu:false,
            curPage:'大包装订购与询价',
            pageName:'',
            menu:[
                {
                    title:'大包装订购与询价',
                    path:'/customerService/largePackaging'
                },
                {
                    title:'快速订购',
                    path:'/customerService/quickOrdering'
                },
            ]
        }
    },
    methods:{
        jumpTo:function (data) {
            this.$router.push(data.path)
        },
        renderCur:function (route) {
            this.pageName = route.meta.title
            this.menu.map(item => {
                if (item.path === route.path){
                    this.curPage = item.title
                }
            })
        }
    },
    mounted() {
        this.renderCur(this.$route)
    },
    watch:{
        '$route'(val){
            this.renderCur(val)
        }
    }
}
</script>

<style scoped>

</style>
